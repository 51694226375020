<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col>
                        Your Projects
                    </b-col>
                    <b-col md="2">
                        <project-router-link to="/iam/projects/create">
                            <b-button variant="primary" class="text-nowrap">
                                <i class="icon-plus mr-1"></i>Add new Project
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="table.columns"
                    :request-function="requestFunction"
                    striped
                    hide-per-page-selector
                    ref="owned-projects-table"
                    :default-per-page="10"
                >
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction
                                :routerLink="
                                    item.authorized ? `/iam/projects/${item.id}` : `/iam/projects/create?id=${item.id}`
                                "
                                v-bind:class="{'action-details-configure': !Boolean(item.authorized)}"
                                :customTitle="Boolean(item.authorized) ? null : 'Configuration Needed!'"
                            />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import DetailsAction from '@/components/Actions/DetailsAction.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    data() {
        return {
            table: {
                apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/projects`,
                columns: ['name', 'role', 'actions'],
            },
        }
    },
    components: {
        DetailsAction,
        WitTable,
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios
                .get(this.table.apiUrl, {
                    params: {
                        ...params,
                    },
                })
                .catch(exception => console.error(exception))

            return {
                data: response.data.data,
                count: response.data.metadata.count,
            }
        },
    },
}
</script>

<style lang="scss">
.action-details-configure {
    a {
        i {
            color: red;
        }
    }
}
</style>
